import { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Col,
  LinkButton,
  Row,
  SkeletonDots,
  useIsMobile
} from '@yes.technology/react-toolkit'

import {
  DetailsContainer,
  StyledCol,
  StyledGenericObjectCard
} from './CardCarousel.styles'
import {
  ActionButton,
  CardCarouselProps,
  CardComponentProps,
  CardProps,
  LinkButtonPropsByObject,
  ModalButtonProps
} from './CardCarousel.types'
import NavigationSlider from './NavigationSlider/NavigationSlider'
import { useImageDocument, useIsOpen } from 'shared/hooks'
import DetailsSection from 'component/DetailsSection'
import { generateSummary } from 'shared/utils/string/summary'
import { StyledGrid } from 'shared/style/grid'
import EmptyStates from 'component/EmptyStates/EmptyStates'
import { useSiteState } from 'siteState/shared'
import useStyleMaps from 'site/Renderer/hooks/useStyleMap'

const MOBILE_PAGE_SIZE = 1
const DESKTOP_PAGE_SIZE = 4

const Card = ({ item, button, onReadMore }: CardComponentProps) => {
  const { imageUrl, isLoading: isLoadingDocument } = useImageDocument({
    document: item.documento || ''
  })

  function isLinkButtonPropsByObject(
    button: ActionButton
  ): button is LinkButtonPropsByObject {
    return (button as LinkButtonPropsByObject)?.[item.uuid] !== undefined
  }

  const objectButton = isLinkButtonPropsByObject(button)
    ? button[item.uuid]
    : undefined

  function isModalButtonProps(
    button: ActionButton
  ): button is ModalButtonProps {
    return (button as ModalButtonProps)?.label !== undefined
  }

  const modalButton = isModalButtonProps(button) ? button : undefined

  return (
    <StyledGenericObjectCard
      des={item.des}
      complement={
        objectButton?.type === 'link'
          ? item.complement
          : generateSummary(item.complement, 150)
      }
      image={(!isLoadingDocument && imageUrl) || ''}
      frameImage
    >
      {modalButton && modalButton.type === 'modal' && (
        <Button onClick={onReadMore}>{modalButton.label}</Button>
      )}

      {objectButton && objectButton.type === 'link' && (
        <LinkButton
          href={objectButton.link.url}
          target={objectButton.link.target}
          variant='primary'
        >
          {objectButton.label}
        </LinkButton>
      )}
    </StyledGenericObjectCard>
  )
}

const CardCarousel = ({ props }: CardCarouselProps) => {
  const [page, setPage] = useState(0)
  const [currentItem, setCurrentItem] = useState<CardProps>()
  const { isOpen, open, close } = useIsOpen(false)
  const isMobile = useIsMobile()
  const styleProps = useStyleMaps(props.styleMap)

  const { title, cards, button } = props

  const pageSize = useMemo(
    () => (isMobile ? MOBILE_PAGE_SIZE : DESKTOP_PAGE_SIZE),
    [isMobile]
  )

  const displayedCards = useMemo(
    () => cards?.slice(page * pageSize, page * pageSize + pageSize),
    [pageSize, cards, page]
  )

  useEffect(() => {
    if (!displayedCards?.[0]) return
    setCurrentItem(displayedCards?.[0])
  }, [page, displayedCards])

  const handlePrevClick = () => {
    setPage((prevPage) => (prevPage === 0 ? prevPage : prevPage - 1))
  }

  const handleNextClick = () => {
    const totalPages = Math.ceil(cards.length / pageSize)

    setPage((prevPage) =>
      prevPage === totalPages - 1 ? prevPage : prevPage + 1
    )
  }

  const openModal = (index: number) => () => {
    setCurrentItem(displayedCards[index])
    open()
  }

  const [allResultsFetched] = useSiteState('allResultsFetched')
  const isLoading = !allResultsFetched

  if (isLoading) {
    return (
      <StyledGrid style={styleProps}>
        <Row style={{ justifyContent: 'center' }}>
          <Col lg={12}>
            <SkeletonDots />
          </Col>
        </Row>
      </StyledGrid>
    )
  }

  return (
    <>
      {displayedCards?.length > 0 && (
        <div style={{ marginBottom: '16px', ...styleProps }}>
          <NavigationSlider
            title={title}
            onNextClick={handleNextClick}
            onPrevClick={handlePrevClick}
            disabled={displayedCards?.length === 0}
            currentIndex={page}
          />

          <StyledGrid>
            <Row style={{ justifyContent: 'center' }}>
              {displayedCards?.map((card, index) => (
                <StyledCol key={index} lg={3}>
                  <Card
                    item={card}
                    button={button}
                    onReadMore={openModal(index)}
                  />
                </StyledCol>
              ))}
            </Row>

            {isOpen && (
              <Row style={{ justifyContent: 'center', marginTop: '16px' }}>
                <Col lg={12}>
                  <DetailsContainer>
                    <DetailsSection
                      isOpen={isOpen}
                      onClose={close}
                      des={currentItem?.des || ''}
                      complement={currentItem?.complement || ''}
                    />
                  </DetailsContainer>
                </Col>
              </Row>
            )}
          </StyledGrid>
        </div>
      )}

      {displayedCards?.length === 0 && (
        <StyledGrid style={styleProps}>
          <Row style={{ justifyContent: 'center' }}>
            <Col lg={12}>
              <EmptyStates state='no-data-to-view' showComplement={false} />
            </Col>
          </Row>
        </StyledGrid>
      )}
    </>
  )
}

export default CardCarousel
