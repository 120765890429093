import Actions from './components/Actions'
import useColumns from './hooks/useColumns'
import { TableContext, TableContextValue } from './hooks/useTableContext'
import {
  ActionColumn,
  ActionColumnsPosition,
  ColumnLayout,
  HierarchyLevel,
  SelectionOption
} from './types'
import {
  Objectclass,
  Pagination as PaginationType,
  SortOption,
  YesObject
} from '@yes.technology/react-toolkit'
import { useEffect, useState } from 'react'
import TableHeader from './components/TableHeader'
import TableBody from './components/TableBody'
import TableActions from './components/TableActions/TableActions'
import useInitialStatus from 'status/shared/hooks/useInitialStatus'
import RenderEmbedded from './components/RenderEmbedded'
import useTableContextValue from './hooks/useTableContextValue'
import DataTableAreaLine from './DataTableAreaLine'
import { useSiteState } from 'siteState/shared'
import { ConcatFields } from 'shared/utils/object/concatenateFields'
import useNavigateMergingState from 'shared/hooks/useNavigateMergingState/useNavigateMergingState'
import { useSearchParams } from 'react-router-dom'

export const defaultActionColumns = [
  {
    title: 'actions',
    component: Actions
  }
]

export type DataTableConfigProps = {
  layoutClassificationUuid?: string
  hierarchyLevel?: HierarchyLevel
  actionButton?: {
    type: 'view' | 'document' | null
    target: '_self' | '_blank'
  }
  hideRowActionButtons?: boolean
  hideMainCollapsibleContainer?: boolean
}

export type DataTableProps = DataTableConfigProps & {
  informational?: boolean
  embedded?: boolean
  idObjectClass: string
  fields: Objectclass['fields']
  objects: YesObject[]
  columns: string[]
  isLoading: boolean
  actionColumns?: ActionColumn[]
  actionColumnsPosition?: ActionColumnsPosition
  onSort: (newSortOptions: SortOption[]) => void
  columnsLayouts?: ColumnLayout[]
  sortOptions?: SortOption[]
  pagination?: PaginationType
  totalObjects?: number
  setCurrentPage: (page: number) => void
  title?: string
  layoutClassificationUuid?: string
  objectclassQueryString?: string
  displayLayoutSelection?: boolean
  navigationSliderBackgroundColor?: string
  navigationSliderColor?: string
  concatFields?: ConcatFields
  shouldResetObjectArea?: boolean
  setShouldResetObjectArea?: (newValue: boolean) => void
  style?: React.CSSProperties
} & Pick<
    TableContextValue,
    | 'objectclass'
    | 'mode'
    | 'setMode'
    | 'changedFieldValues'
    | 'setChangedFieldValues'
    | 'refetchObjects'
    | 'relationCounterpartField'
    | 'reportDownloadTriggered'
    | 'triggerReportDownload'
    | 'reportUrl'
  >

const emptyArray: Array<unknown> = []

const DataTable = ({
  idObjectClass,
  objectclass,
  fields,
  objects,
  refetchObjects,
  columns,
  isLoading,
  actionColumns = defaultActionColumns,
  actionColumnsPosition = 'last',
  onSort,
  columnsLayouts = emptyArray as ColumnLayout[],
  sortOptions = emptyArray as SortOption[],
  pagination,
  totalObjects,
  setCurrentPage,
  title = '',
  layoutClassificationUuid,
  hierarchyLevel = 'level_2',
  actionButton,
  mode,
  setMode,
  changedFieldValues,
  setChangedFieldValues,
  relationCounterpartField,
  reportDownloadTriggered,
  triggerReportDownload,
  reportUrl,
  objectclassQueryString = 'uuid_objectclass',
  displayLayoutSelection = true,
  informational = false,
  embedded = false,
  navigationSliderBackgroundColor = '#004099',
  navigationSliderColor,
  concatFields,
  shouldResetObjectArea,
  setShouldResetObjectArea,
  hideMainCollapsibleContainer = false,
  hideRowActionButtons = false,
  style
}: DataTableProps) => {
  const [selectedRows, setSelectedRows] = useState<
    Record<string, SelectionOption>
  >({})

  const {
    displayedColumns,
    displayPreviousColumns,
    displayNextColumns,
    currentColumnIndex,
    unpinnedColumns,
    pinnedColumns
  } = useColumns({ columns, columnsLayouts })

  const tableContextValue = useTableContextValue({
    idObjectClass,
    objectclass,
    fields,
    actionColumns,
    actionColumnsPosition,
    columnsNames: displayedColumns,
    pinnedColumns,
    objects,
    refetchObjects,
    columnsLayouts,
    onSort,
    sortOptions,
    displayPreviousColumns,
    displayNextColumns,
    currentColumnIndex,
    unpinnedColumns,
    pagination,
    totalObjects,
    layoutClassificationUuid,
    hierarchyLevel,
    actionButton,
    selectedRows,
    setSelectedRows,
    mode,
    setMode,
    changedFieldValues,
    setChangedFieldValues,
    relationCounterpartField,
    reportDownloadTriggered,
    triggerReportDownload,
    reportUrl,
    objectclassQueryString,
    displayLayoutSelection,
    informational,
    embedded,
    navigationSliderBackgroundColor,
    navigationSliderColor,
    concatFields,
    hideRowActionButtons
  })

  const initialStatus = useInitialStatus({ objectclassUuid: idObjectClass })

  const navigateMergingState = useNavigateMergingState()

  const [_, setObjectIndex] = useSiteState<number | undefined>('object-index')

  const paginationOffset = pagination?.offset || 0

  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (hideRowActionButtons || objects.length === 0 || !shouldResetObjectArea)
      return

    searchParams.set('object-uuid', objects[0].uuid)
    searchParams.set(objectclassQueryString, idObjectClass)

    const search = `?${searchParams.toString()}`

    setObjectIndex(paginationOffset)

    setShouldResetObjectArea?.(false)

    navigateMergingState(
      {
        search,
        hash: ''
      },
      {
        state: {
          sortOptions
        },
        replace: true
      }
    )
  }, [
    objects,
    navigateMergingState,
    objectclassQueryString,
    idObjectClass,
    sortOptions,
    embedded,
    setObjectIndex,
    shouldResetObjectArea,
    setShouldResetObjectArea,
    hideRowActionButtons,
    paginationOffset,
    searchParams
  ])

  return (
    <TableContext.Provider value={tableContextValue}>
      <DataTableAreaLine
        {...{
          hierarchyLevel,
          title,
          navigationSliderBackgroundColor,
          navigationSliderColor,
          pagination,
          setCurrentPage,
          totalObjects,
          embedded,
          hideMainCollapsibleContainer
        }}
        aria-busy={isLoading}
        aria-live='assertive'
        style={style}
      >
        <RenderEmbedded className='pb-4' embedded={embedded}>
          <div className='row'>
            <div className='col-md-12'>
              <div className='table-responsive'>
                {!informational && !isLoading && (
                  <TableActions
                    {...{
                      layoutClassificationUuid,
                      initialStatus
                    }}
                  />
                )}
                <table className='table'>
                  <TableHeader {...{ isLoading }} />
                  <TableBody {...{ isLoading }} />
                </table>
              </div>
            </div>
          </div>
        </RenderEmbedded>
      </DataTableAreaLine>
    </TableContext.Provider>
  )
}

export default DataTable
