import { Record, String, Array, Optional, Unknown } from 'runtypes'
import { HeroBannerButton } from './HeroBanner/HeroBanner.types'
import { StyleMap } from 'site/Renderer/hooks/useStyleMap'

export type HeroBannerContainerProps = {
  props: {
    des?: string
    complement?: string
    buttons?: HeroBannerButton[]
    backgroundImageUrl: string
    backgroundImageUrlMobile?: string
    imageAlt?: string
    desColor?: string
    complementColor?: string
    styleMap?: StyleMap
  }
}

const ButtonTypeGuard = Record({
  label: String,
  href: String,
  target: Optional(String)
})

export const HeroBannerContainerTypeGuard = Record({
  des: Optional(String),
  complement: Optional(String),
  buttons: Optional(Array(ButtonTypeGuard)),
  backgroundImageUrl: String,
  backgroundImageUrlMobile: Optional(String),
  imageAlt: Optional(String),
  desColor: Optional(String),
  complementColor: Optional(String),
  styleMap: Optional(Unknown)
})
