import {
  FieldValues,
  FilterField,
  FilterSectionLayout,
  GroupedFieldValues
} from '../../types'
import Field from '../Field/Field'

type FilterFormProps = {
  sectionLayout?: FilterSectionLayout
  fields: FilterField[]
  fieldValues: FieldValues
  handleChange?: (value: FieldValues) => void
  uuidCurrentSitemodelObjectclass?: string
  groupedFieldValues: GroupedFieldValues
}

const Fields = ({
  sectionLayout,
  fields,
  fieldValues,
  handleChange,
  uuidCurrentSitemodelObjectclass,
  groupedFieldValues
}: FilterFormProps) => {
  return (
    <div className='container-fluid px-0'>
      <div className='row align-items-end'>
        {fields.map((field) => {
          if (!field.uuid) return null
          return (
            <Field
              key={field.uuid}
              props={field}
              fieldLayout={sectionLayout?.filter_fields.find(
                (fieldLayout) => fieldLayout.field_uuid === field.uuid
              )}
              {...{
                handleChange,
                fieldValues,
                uuidCurrentSitemodelObjectclass,
                groupedFieldValues
              }}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Fields
