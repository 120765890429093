import { useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18n-lite'

import { Button, Icon, LinkButton } from '@yes.technology/react-toolkit'

import { TaskActionsContainer, TaskActionsRow } from './TaskObjectCard.styles'

import { TaskActionsProps } from './TaskObjectCard.types'

const TaskActions = ({
  statusUuid,
  taskUuid,
  statusActions,
  changeStatus
}: TaskActionsProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const isWaitingInteraction =
    window.waitingInteractionStatusUuid === statusUuid

  return (
    <TaskActionsContainer>
      {isWaitingInteraction && (
        <TaskActionsRow>
          <Button
            variant='primary'
            heightClass='small'
            onClick={() => navigate(`/tasks/${taskUuid}`)}
          >
            {t('task.action.execute')}
          </Button>
        </TaskActionsRow>
      )}
      {statusActions.map((statusAction, i) => (
        <TaskActionsRow key={statusAction.destination_status[0].uuid}>
          <Button
            variant='secondary'
            heightClass='small'
            onClick={() =>
              changeStatus(statusAction.destination_status[0].uuid)
            }
          >
            {statusAction.des}
          </Button>
          {i === statusActions.length - 1 && (
            <LinkButton
              key='view'
              variant='secondary'
              heightClass='small'
              style={{ width: '24px', minWidth: '24px' }}
              aria-label={t('task.action.view')}
              href={`/${window.objectDetailsSitemodelUuid}?uuid_objectclass=${window.uuidObjectclassTask}&object-uuid=${taskUuid}`}
            >
              <Icon iconName='Visualization' icSize='small' />
            </LinkButton>
          )}
        </TaskActionsRow>
      ))}
    </TaskActionsContainer>
  )
}

export default TaskActions
