import { FilterForm } from './components'
import { FilterQuery, Objectclass } from '@yes.technology/react-toolkit'
import { useFilterFields } from 'shared/hooks'
import { useSitemodel } from 'site/Render/hooks'
import { useSiteState } from 'siteState/shared'
import { FilterFieldsResponse } from 'shared/hooks/useFilterFields/useFilterFields'
import { useCallback, useEffect, useMemo } from 'react'
import { FilterAreaLayout } from './types'
import { FieldSettings } from 'shared/types'
import useStyleMaps, { StyleMap } from 'site/Renderer/hooks/useStyleMap'

export type FilterFieldsDisplayOptions = {
  [elementUuid: string]: {
    hideFilteraction?: boolean
    hideMainCollapsibleContainer?: boolean
    hideFieldsCollapsibleContainer?: boolean
  }
}

type FilterFieldsProps = {
  props: {
    elementUuid: string
    uuid_objectclass: string
    uuid_classification?: string
    defaultExecutedFilteraction?: string
    navigationslider_background_color?: string
    navigationslider_color?: string
    filter_area_layout?: FilterAreaLayout
    field_settings?: FieldSettings
    styleMap?: StyleMap
  }
}

const FilterFields = ({ props }: FilterFieldsProps) => {
  const sitemodel = useSitemodel()

  const {
    elementUuid,
    uuid_objectclass: objectclassUuid,
    uuid_classification: classificationUuid,
    defaultExecutedFilteraction,
    navigationslider_background_color: navigationsliderBackgroundColor,
    navigationslider_color: navigationsliderColor,
    filter_area_layout: filterAreaLayout,
    field_settings: fieldSettings,
    styleMap
  } = props

  const styleProp = useStyleMaps(styleMap)

  const {
    filteractions: { hide_filteraction: hideFilteraction = false } = {},
    hide_main_collapsible_container: hideMainCollapsibleContainer = false,
    hide_fields_collapsible_container: hideFieldsCollapsibleContainer = false
  } = filterAreaLayout || {}

  const [, setSiteQuery] = useSiteState<Partial<FilterQuery>>('filter-query')
  const [, setSiteObjectIndex] = useSiteState<number | undefined>(
    'object-index'
  )
  const [, setSiteFields] = useSiteState<FilterFieldsResponse>('filter-fields')
  const [, setObjectclassUUID] = useSiteState<string>('objectclassuuid')
  const [, setFilterObjectclass] = useSiteState<Objectclass | undefined>(
    'filter-objectclass'
  )
  const [, setDisplayOptions] = useSiteState<FilterFieldsDisplayOptions>(
    'filter-display-options'
  )

  const fields = useFilterFields({
    objectclassUuid,
    classificationUuid,
    filterAreaLayout,
    fieldSettings
  })

  const initialGroupedFieldValues = useMemo(
    () => ({ 1: fields.initialFieldValues }),
    [fields.initialFieldValues]
  )

  useEffect(() => {
    setFilterObjectclass(fields.objectclass)
  }, [fields, setFilterObjectclass])

  useEffect(() => {
    setDisplayOptions((prevState) => ({
      ...prevState,
      [elementUuid]: {
        hideFilteraction,
        hideMainCollapsibleContainer,
        hideFieldsCollapsibleContainer
      }
    }))
  }, [
    elementUuid,
    hideFieldsCollapsibleContainer,
    hideFilteraction,
    hideMainCollapsibleContainer,
    setDisplayOptions
  ])

  const onClickFilter = useCallback(
    (query: Partial<FilterQuery>) => {
      setSiteObjectIndex(undefined)
      setSiteQuery(query)
      setSiteFields(fields)
      setObjectclassUUID(objectclassUuid)
    },
    [
      setSiteObjectIndex,
      setSiteQuery,
      setSiteFields,
      setObjectclassUUID,
      fields,
      objectclassUuid
    ]
  )

  const key = useMemo(() => JSON.stringify(fields), [fields])

  if (!fields.classifiedFields[0].length) return null

  const sitemodelTitle = sitemodel?.title
  const objectclassName = fields?.objectclass?.des

  return (
    <FilterForm
      key={key}
      filterAreaLayout={filterAreaLayout}
      initialGroupedFieldValues={initialGroupedFieldValues}
      sections={fields.classifiedFields}
      onSubmit={onClickFilter}
      title={sitemodelTitle || objectclassName}
      uuidCurrentSitemodelObjectclass={props.uuid_objectclass}
      defaultExecutedFilteraction={defaultExecutedFilteraction}
      navigationSliderBackgroundColor={navigationsliderBackgroundColor}
      navigationSliderColor={navigationsliderColor}
      style={styleProp}
      {...{ elementUuid }}
    />
  )
}

export default FilterFields
