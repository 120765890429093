import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import { Icon } from '@yes.technology/react-toolkit'
import { useTranslation } from 'react-i18n-lite'
import { DetailsSectionProps, ScrollToViewHandle } from './DetailsSection.types'
import {
  CloseButton,
  FullText,
  StyledHierarchyCard,
  Title
} from './DetailsSection.styles'
import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'

const DetailsSection = forwardRef<ScrollToViewHandle, DetailsSectionProps>(
  ({ onClose, isOpen, des, complement }, ref) => {
    const { t } = useTranslation()
    const fullTextRef = useRef<HTMLDivElement>(null)

    useImperativeHandle(
      ref,
      () => ({
        scrollToContent: () => {
          if (isOpen && fullTextRef?.current) {
            fullTextRef.current.scrollIntoView({ behavior: 'smooth' })
          }
        }
      }),
      [isOpen]
    )

    if (!isOpen) return null

    return (
      <div ref={fullTextRef}>
        <StyledHierarchyCard>
          <CloseButton
            aria-label={t('system.close')}
            title={t('system.close')}
            onClick={onClose}
          >
            <Icon iconName='Close' icColor='_006BFF' />
          </CloseButton>
          <Title>{des}</Title>
          <FullText>
            <SafeHtmlContent html={complement} />
          </FullText>
        </StyledHierarchyCard>
      </div>
    )
  }
)
DetailsSection.displayName = 'DetailsSection'

export default DetailsSection
